import React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import logoSrc from "./AcesoftLogo.png";
import VerticalNavItem from "./VerticalNavItem";
import { ListItemText } from "@mui/material";

const CompanyLogo: React.FC = () => {
  const theme = useTheme();

  return (
    <VerticalNavItem>
      <Box
        component="a"
        href="https://acesoft.ca/"
        target="_blank"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          textDecoration: "none",
          color: theme.palette.text.primary,
          borderRadius: theme.spacing(1),
          transition: "background-color 0.3s ease",
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        }}
      >
        {/* Logo and Company Name */}
        <Box sx={{ display: "flex", alignItems: "center", marginBottom: theme.spacing(1) }}>
          <img
            src={logoSrc}
            alt="Company Logo"
            style={{
              height: "auto",
              maxHeight: "40px",
              width: "auto",
              maxWidth: "40px",
            }}
          />
          <ListItemText
            primary="Developed by: AceSoft Inc"
            primaryTypographyProps={{
              variant: "subtitle1",
              fontWeight: "bold",
              marginLeft: theme.spacing(1),
              color: theme.palette.primary.main,
            }}
          />
        </Box>

        {/* Version Information */}
        <Typography
          variant="caption"
          sx={{
            color: theme.palette.text.secondary,
            fontWeight: 500,
            fontSize: "0.8rem",
            textAlign:"center",
            width:"100%"
          }}
        >
          Version 0.0.1
        </Typography>
      </Box>
    </VerticalNavItem>
  );
};

export default CompanyLogo;
