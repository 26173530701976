import React from 'react';
import { useThemeContext } from '@crema/context/AppContextProvider/ThemeContextProvider';
import { alpha, Box } from '@mui/material';

type AppLogoProps = {
  color?: string;
};

const AppLogo: React.FC<AppLogoProps> = ({ color }) => {
  const { theme } = useThemeContext();

  return (
    <Box
      sx={{
        height: { xs: 56, sm: 70 },
        padding: 2.5,
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
          height: { xs: 40, sm: 45 },
        },
      }}
      className='app-logo'
    >
      <img
        src='https://cellcraft.ae/wp-content/uploads/2023/09/Group-16822@2x-178x46.png' // Replace with the path to your logo image
        alt='App Logo'
        style={{ height: '100%' }} // Adjust height if needed
      />
      {/* Optional: You can add text or another image next to the logo */}
      {/* <Box
        sx={{
          fontSize: 30,
          fontWeight: 900,
          fontFamily: 'fantasy',
          mt: 1,
          display: { xs: 'none', md: 'block' },
        }}
      >
        Mobile Service
      </Box> */}
    </Box>
  );
};

export default AppLogo;
