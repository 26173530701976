import React, { useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import NavVerticalGroup from "./VerticalNavGroup";
import VerticalCollapse from "./VerticalCollapse";
import VerticalItem from "./VerticalItem";
import { RouterConfigData } from "@crema/types/models/Apps";
import CompanyLogo from "./VerticalItem/comapnylogo";

type Props = {
  routesConfig: RouterConfigData[];
};

const VerticalNav = ({ routesConfig }: Props) => {
  const [openItemId, setOpenItemId] = useState<string | null>(null); // Track the currently open item

  const handleToggle = (itemId: string) => {
    setOpenItemId((prevOpenItemId) => (prevOpenItemId === itemId ? null : itemId)); // Toggle the section
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        overflow: "hidden", // Prevent content from spilling outside
      }}
    >
      {/* Navigation List */}
      <List
        sx={{
          flexGrow: 1, // Allow the list to take available space
          padding: 0,
          overflowY: "auto", // Enable scrolling
          marginBottom: 2, // Add spacing between list and logo
        }}
        component="div"
      >
        {routesConfig.map((item: RouterConfigData) => (
          <React.Fragment key={item.id}>
            {item.type === "group" && <NavVerticalGroup item={item} level={0} />}

            {item.type === "collapse" && (
              <VerticalCollapse
                item={item}
                level={0}
                open={openItemId === item.id} // Only open if the item is the current open item
                onToggle={() => handleToggle(item.id)} // Handle toggle for this item
              />
            )}

            {item.type === "item" && <VerticalItem item={item} level={0} />}
          </React.Fragment>
        ))}
      </List>

      {/* Logo at the Bottom */}
      <Box
        sx={{
          // pb: 2,\
        height:"100px",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderTop: "1px solid #e0e0e0", // Optional separator
          paddingTop: 2, // Add padding above the logo for separation
        }}
      >
        <CompanyLogo />
      </Box>
    </Box>
  );
};

export default VerticalNav;
