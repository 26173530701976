import React, { useState } from 'react';
import { Box, Button, Typography, Grid, TextField } from '@mui/material';
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';
import { Fonts } from '@crema/constants/AppEnums';
import { useAuthUser } from '@crema/hooks/AuthHooks';
import jwtAxios from '@crema/services/auth/jwt-auth';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useJWTAuthActions } from '@crema/services/auth/jwt-auth/JWTAuthProvider';

const handleAdd = async (values, id, fetchUser) => {
  try {
    // Ensure VAT is properly formatted as a decimal
    const vat = parseFloat(values.vat);
    if (!isNaN(vat)) {
      values.vat = vat;
    } else {
      throw new Error("Invalid VAT value");
    }

    const response = await jwtAxios.put('/branch/updateProfile/' + id, values);
    toast.success('Status updated successfully!');
    fetchUser();
  } catch (error) {
    toast.error(error.response?.data?.message || "Something went wrong, please try again.");
  }
};

const validationSchema = Yup.object().shape({
  branchName: Yup.string().required('Branch Name is required'),
  branchAddress: Yup.string().required('Branch Address is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone: Yup.string().required('Phone number is required'),
  inCharge: Yup.string().required('In Charge is required'),
  servicePrefix: Yup.string()
    .max(3, 'Service Prefix must be at most 3 characters')
    .required('Service Prefix is required'),
  invoiceFirstPrefix: Yup.string()
    .max(3, 'Invoice First Prefix must be at most 3 characters')
    .required('Invoice First Prefix is required'),
  invoiceSecondPrefix: Yup.string()
    .max(3, 'Invoice Second Prefix must be at most 3 characters')
    .required('Invoice Second Prefix is required'),
  website: Yup.string().required('Website is required'),
  vat: Yup.number().typeError('VAT must be a number').required('VAT is required').min(0, 'VAT must be a positive number'),
});

const FormikTextField = ({ label, ...props }: any) => {
  const [field, meta] = useField(props);
  return (
    <TextField
      label={label}
      {...field}
      {...props}
      error={Boolean(meta.touched && meta.error)}
      helperText={meta.touched && meta.error ? meta.error : ''}
    />
  );
};

const PersonalInfoForm = ({ values }) => {
  const [isEditMode, setEditMode] = useState(false);
  const { fetchUser } = useJWTAuthActions();

  return (
    <Formik
      initialValues={values}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await handleAdd(values, values.id, fetchUser);
        setSubmitting(false);
      }}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({ handleSubmit, validateForm, setTouched }) => (
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Typography
            component="h3"
            sx={{
              fontSize: 16,
              fontWeight: Fonts.BOLD,
              mb: { xs: 3, lg: 4 },
            }}
          >
            Company Info
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: { xs: 5, lg: 6 },
            }}
          >
            <Box sx={{ ml: 4 }}>
              <Typography sx={{ fontWeight: Fonts.MEDIUM }}>
                {values.branchName || ''}
              </Typography>
              <Typography sx={{ color: (theme) => theme.palette.text.secondary }}>
                {values.email || ''}
              </Typography>
            </Box>
          </Box>
          <Button
            onClick={() => setEditMode(!isEditMode)}
            color="primary"
            variant="contained"
            sx={{ mb: 3 }}
          >
            {isEditMode ? "Cancel Edit" : "Edit"}
          </Button>
          <Grid container spacing={4}>
            {isEditMode ? (
              <>
                <Grid item xs={12} md={6}>
                  <FormikTextField
                    name="branchName"
                    label="Branch Name"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikTextField
                    name="branchAddress"
                    label="Branch Address"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikTextField
                    name="phone"
                    label="Phone"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikTextField
                    name="email"
                    label="Email"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikTextField
                    name="vat"
                    label="VAT"
                    type="number"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikTextField
                    name="website"
                    label="Website"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikTextField
                    name="servicePrefix"
                    label="Service Prefix"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikTextField
                    name="invoiceFirstPrefix"
                    label="Invoice First Prefix"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikTextField
                    name="invoiceSecondPrefix"
                    label="Invoice Second Prefix"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                      sx={{ position: 'relative', minWidth: 100 }}
                      color="primary"
                      variant="contained"
                      type="submit"
                      onClick={async () => {
                        const errors = await validateForm();
                        if (Object.keys(errors).length > 0) {
                          setTouched({
                            branchName: true,
                            branchAddress: true,
                            phone: true,
                            email: true,
                            vat: true,
                            website: true,
                            servicePrefix: true,
                            invoiceFirstPrefix: true,
                            invoiceSecondPrefix: true,
                          });
                        }
                      }}
                    >
                      Save Changes
                    </Button>
                  </Box>
                </Grid>
              </>
            ) : (
              <>
                {/* Static display when not in edit mode */}
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>Branch Name:</strong> {values.branchName || 'Acme Corp'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>Branch Address:</strong> {values.branchAddress || '123 Main St, Anytown, USA'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>Phone:</strong> {values.phone || '+1 234 567 890'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>Email:</strong> {values.email || 'contact@acmecorp.com'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>Website:</strong> {values.website || 'www.acmecorp.com'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>Service Prefix:</strong> {values.servicePrefix || 'TST'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>Invoice 1st Prefix:</strong> {values.invoiceFirstPrefix || 'TST'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>Invoice 2nd Prefix:</strong> {values.invoiceSecondPrefix || 'BB'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>VAT:</strong> {values.vat !== undefined ? values?.vat : 'N/A'}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

const App = () => {
  const { user } = useAuthUser();
  return <PersonalInfoForm values={user} />;
};

export default App;
