
import PersonalInfoForm from './PersonalInfoForm';
import { Box } from '@mui/material';


const PersonalInfo = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        maxWidth: 550,
      }}
    >
    
            <PersonalInfoForm />
    </Box>
  );
};

export default PersonalInfo;
