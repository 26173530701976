import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AuthUserType } from '@crema/types/models/AuthUser';
import jwtAxios, { setAuthToken } from './index';
import { useInfoViewActionsContext } from '@crema/context/AppContextProvider/InfoViewContextProvider';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface JWTAuthContextProps {
  user: AuthUserType | null | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
}

interface SignUpProps {
  name: string;
  email: string;
  password: string;
}

interface SignInProps {
  email: string;
  password: string;
}

interface JWTAuthActionsProps {
  signUpUser: (data: SignUpProps) => void;
  signInUser: (data: SignInProps) => void;
  logout: () => void;
  fetchUser: () => void;
}

const JWTAuthContext = createContext<JWTAuthContextProps>({
  user: null,
  isAuthenticated: false,
  isLoading: true,
});
const JWTAuthActionsContext = createContext<JWTAuthActionsProps>({
  signUpUser: () => {},
  signInUser: () => {},
  logout: () => {},
  fetchUser: () => {},
});

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

interface JWTAuthAuthProviderProps {
  children: ReactNode;
}

const JWTAuthAuthProvider: React.FC<JWTAuthAuthProviderProps> = ({
  children,
}) => {
  const [jwtAuthData, setJWTAuthData] = useState<JWTAuthContextProps>({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  const infoViewActionsContext = useInfoViewActionsContext();

  const fetchUser = async () => {
    const token = localStorage.getItem('token');

    if (!token) {
      setJWTAuthData({
        user: undefined,
        isLoading: false,
        isAuthenticated: false,
      });
      return;
    }
    setAuthToken(token);
    try {
      const { data } = await jwtAxios.get('/adminAuth/login');
      setJWTAuthData({
        user: data,
        isLoading: false,
        isAuthenticated: true,
      });
    } catch {
      setJWTAuthData({
        user: undefined,
        isLoading: false,
        isAuthenticated: false,
      });
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const signInUser = async ({ email, password }: SignInProps) => {
    infoViewActionsContext.fetchStart();
    try {
      const { data } = await jwtAxios.post('/adminAuth/login', { email, password });
      localStorage.setItem('token', data.token);
      setAuthToken(data.token);
      await fetchUser(); // Refetch user after login
      infoViewActionsContext.fetchSuccess();
    } catch (error) {
      setJWTAuthData({
        ...jwtAuthData,
        isAuthenticated: false,
        isLoading: false,
      });
      infoViewActionsContext.fetchError(error.response.data.message || 'Something went wrong');
      toast.error(error.response.data.message||'Something went wrong')
    }
  };

  const signUpUser = async ({ name, email, password }: SignUpProps) => {
    infoViewActionsContext.fetchStart();
    try {
      const { data } = await jwtAxios.post('users', { name, email, password });
      localStorage.setItem('token', data.token);
      setAuthToken(data.token);
      await fetchUser(); // Refetch user after signup
      infoViewActionsContext.fetchSuccess();
    } catch (error) {
      setJWTAuthData({
        ...jwtAuthData,
        isAuthenticated: false,
        isLoading: false,
      });
      infoViewActionsContext.fetchError('Something went wrong');
    }
  };

  const logout = async () => {
    localStorage.removeItem('token');
    setAuthToken();
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...jwtAuthData,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          signUpUser,
          signInUser,
          logout,
          fetchUser, // Expose the fetchUser function
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};

export default JWTAuthAuthProvider;
